#carroussel-news > div:nth-child(2){
    // max-height: 35vh;
    // min-height: 35vh;
    // height: 35vh;
}

#carroussel-news{
    width: 100%;
}

.button-banner{
    position: absolute;
    right: 10px;
    background-color: rgb(70, 49, 0);
    color: rgb(242, 227, 132);
}

#carroussel-news > div {
    width: 100%;
}

.ui-carousel__item{
    width: 100% !important;
    padding-left: 35px;
    padding-right: 25px;
}


.apps-carousel {
    display: flex !important;
    flex-direction: column;
    width: 100% !important;
    height: 100%;
    max-height: 100%;
}

.apps-carousel > *,
.apps-carousel .ui-carousel__itemscontainer,
.apps-carousel .ui-carousel__item {
    width: 100% !important;
    height: 100% !important;
}

.apps-carousel .ui-carousel__item {
    min-height: 100px;
}

.apps-carousel .ui-carousel__navigation {
    height: 30px !important;
}

.apps-carousel .ui-carousel__item .ui-text {
    overflow-wrap: break-word;
    width: 430px;
}

.apps-carousel .app-icon {
    min-width: 120px;
    min-height: 120px;
    max-width: 120px;
    max-height: 120px;
    border-radius: 8px;
}

.apps-carousel .ui-carousel__navigationitem__indicator {
    height: 10px;
    width: 10px;
}

div:has(> iframe.ql-video) {
    height: inherit !important;
    width: 100%;
}

.ui-carousel__item > div img {
    width: 80%;
    height: 90%;
    max-width: 1080px;
    max-height: 980px;
}

iframe.ql-video {
    display: block;
    width: 80%;
    height: 90%;
    max-width: 1080px;
    max-height: 980px;
}