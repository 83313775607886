#teamsDefault {
    .richTextEditor {
        background-color: white;
        overflow: visible;
    }

    .richTextEditor .ql-editor a {
        color: #6264A7;
        //text-decoration: none;
    }

    .richTextEditor .ql-tooltip {
        border: 1px solid rgb(225, 223, 221) !important;
        background-color: white !important;
        box-shadow: rgb(0 0 0 / 10%) 0px 0.2rem 0.4rem -0.075rem !important;
    }

    .richTextEditor .ql-tooltip input {
        color: black !important;
        background-color: rgb(243, 242, 241) !important;
    }

    .richTextEditor .ql-tooltip p {
        color: black;
    }

    .ql-container.ql-snow{
        border: none !important;
    }
    
    .ql-snow {
        width: 100% !important;
        background-color: white !important;
    }

    .ql-toolbar .ql-active .ql-stroke {
        fill: none !important;
        stroke: #9ea2ff !important;
    }

    .ql-toolbar .ql-active .ql-fill {
        fill: #9ea2ff !important;
        stroke: none !important;
    }

    .ql-toolbar .ql-active .ql-picker {
        color: #9ea2ff !important;
    }

    .richTextEditor .ql-tooltip .ql-action, .richTextEditor .ql-tooltip .ql-remove {
        color: #6264A7 !important;
    }

    .ql-toolbar button:hover * {
        fill: none !important;
        stroke: #6264A7 !important;
    }
    .ql-remove{
        color: black !important;
    }

    .ql-tooltip a.ql-action::after{
        color: black !important;
    }
    .ql-tooltip a.ql-remove::before{
        color: black !important;
    }
}

#teamsDark {
    .richTextEditor {
        background-color: rgb(45, 44, 44);
        overflow: visible;
    }

    .richTextEditor .ql-tooltip {
        border: 1px solid rgb(96, 94, 92) !important;
        background-color: #1f1f1f !important;
        box-shadow: none !important;
    }

    .richTextEditor .ql-tooltip input {
        color: white;
        background-color: rgb(45, 44, 44);
    }

    .ql-container.ql-snow {
        border: none !important;
    }

    .ql-toolbar .ql-active .ql-stroke {
        fill: none !important;
        stroke: #9ea2ff !important;
    }

    .ql-toolbar .ql-active .ql-fill {
        fill: #9ea2ff !important;
        stroke: none !important;
    }
  
    .ql-toolbar .ql-active .ql-picker {
        color: #9ea2ff !important;
    }

    .richTextEditor .ql-tooltip .ql-action, .richTextEditor .ql-tooltip .ql-remove {
        color: #9ea2ff !important;
    }

    .ql-toolbar button:hover * {
        fill: none !important;
        stroke: #6264A7 !important;
    }

    .ql-snow .ql-picker.ql-header .ql-picker-label::before {
        color: white !important;
    }
    
    .ql-snow .ql-stroke {
        stroke: white !important;
    }
    .ql-remove{
        color: white !important;
    }

    .ql-tooltip a.ql-action::after{
        color: white !important;
    }
    .ql-tooltip a.ql-remove::before{
        color: white !important;
    }
}

#teamsHighContrast {
    .richTextEditor {
        background-color: black;
        border: 1px solid white !important;
        overflow: visible;
    }

    .richTextEditor .ql-tooltip {
        border: 1px solid white !important;
        background-color: black !important;
        box-shadow: none !important;
    }

    .richTextEditor .ql-tooltip input {
        color: white;
        background-color: rgb(45, 44, 44);
    }

    .ql-toolbar .ql-active .ql-stroke {
        fill: none !important;
        stroke: #1aebff !important;
    }

    .ql-toolbar .ql-active .ql-fill {
        fill: #1aebff !important;
        stroke: none !important;
    }

    .ql-toolbar .ql-active .ql-picker {
        color: #1aebff !important;
    }

    .richTextEditor .ql-tooltip .ql-action, .richTextEditor .ql-tooltip .ql-remove {
        color: #1aebff !important;
    }

    .ql-toolbar button:hover * {
        fill: none !important;
        stroke: #ffff01 !important;
    }

    .richTextEditor .ql-editor a {
        color: #a8acb4 !important;
    }

    .ql-tooltip a.ql-action::after{
        color: white !important;
    }
    .ql-tooltip a.ql-remove::before{
        color: white !important;
    }
}

.richTextContainer {
    position: relative;
    overflow: visible !important;
}

.quill {
    margin-top: 34px;
    width: 100%;
    height: calc(100% - 34px);
    overflow-y: auto;
}

.quill .ql-toolbar {
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    border: none !important;
    padding: 5px 0 !important;
}

.richTextEditor {
    display: flex;
    padding: 10px 12px !important;
    border-radius: 0.3rem 0.3rem 0.2rem 0.2rem;
    border: none !important;
    font-family: inherit !important;
    font-size: 100% !important;
    height: auto !important;
}

.richTextEditor .ql-editor {
    width: 100%;
    padding: 0 !important;
    height: 100%;
    text-wrap: normal;
    overflow-wrap: anywhere !important;
}

.richTextEditor * {
    margin-top: 0 !important;
    font-family: inherit !important;
    font-size: 100% !important;
}

.richTextEditor p, .richTextEditor ul, .richTextEditor ol {
    //margin-bottom: 14px !important;
}

.ql-editor ol li:not(.ql-direction-rtl), .ql-editor ul li:not(.ql-direction-rtl) {
    padding-left: 0 !important;
}

.ql-editor ul, .ql-editor ol {
    padding-left: 4rem !important;
}

.ql-editor ul > li::before {
    content: none !important;
}

.ql-editor ol > li, .ql-editor ul > li {
    list-style-type: inherit !important;
}

.ql-preview {
    color: grey !important;
}

.richTextEditor .ql-tooltip {
    position: absolute;
    z-index: 999;
    left: 10px !important;
    border-radius: 4px !important;
}

.richTextEditor .ql-container {
    width: 100% !important;
}

.ql-tooltip.ql-editing {
    //top: 0px !important;
    //left: 100px !important;
}

.richTextEditor .ql-tooltip input {
    border: none !important;
    border-radius: 4px;
    width: 200px !important;
}

.ql-tooltip a::after {
    margin-left: 10px !important;
}

.ql-tooltip p {
    color: white;
    margin-right: 10px;
    margin-bottom: 0 !important;
}

.ql-tooltip {
    display: flex;
    align-items: center;
}

.ql-tooltip::before {
    color: white !important;
    content: none !important;
}

.ql-align-center {
    text-align: center !important;
    display: block;
    margin: 0 auto;
  }
  
  .ql-align-left {
    text-align: left !important;
  }
  
  .ql-align-right {
    text-align: right !important;
    display: block;
    margin: 0 auto;
  }
  
  .ql-align-justify {
    text-align: justify !important;
  }

.ql-editor .ql-video {
    display: block;
    width: 100% !important;
    height: 315px !important;
}

.markdown-view { width: 100%; height: 100%; max-width: 30vw; max-height: 300px; p { margin: 0; overflow-wrap: break-word; line-height: 1.3; min-height: 18px; } }