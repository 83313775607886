.home-content {
    overflow-y: scroll;
    overflow-x: hidden;
}
.errorTag{
    padding: 34px;
    color: red; 
}
.actionTag{
    width: inherit;
    //float: right;
}

.actionTag > div {
    width: inherit;
}

.pull-right{
    float: right;
    width: fit-content !important;
}

.pull-left{
    float: left;
    width: fit-content !important;
}
.posError{
    position: absolute;
    left: 0;
    color: red; 
    max-width: 80%;
}