// SETTINGS
$padding: 15px;
$mobile-padding: 15px;

// LIGHT COLORS
$color-100-light: #FDF9F4;
$color-200-light: #F5EEEA;
$color-300-light: #E9D8CD;
$color-400-light: #EAC294;
$color-500-light: #DA9140;
$color-primary-light: #414e78;
$color-primary-lighter-light: #4b6393;
$color-gray-light: #8893A5;
$color-gray-lighter-light: #F3F4F6;
$color-red-light: #DB5656;

// DARK COLORS
$color-100-dark: #E9D8CD;
$color-200-dark: #756C67;
$color-300-dark: #6D4920;
$color-400-dark: #412B13;
$color-500-dark: #DA9140;
$color-primary-dark: #6c82c9;
$color-primary-lighter-dark: #839def;
$color-gray-dark: #444A53;
$color-gray-lighter-dark: #8893A5;
$color-red-dark: #DB5656;