$color_1: white;
$background-color_1: white;

.darkContainer {
	.gm-style {
		.gm-style-iw-d {
			background: #1F1F1F;
			color: $color_1;
		}
		.gm-style-iw-c {
			background: #1F1F1F;
			color: $color_1;
			.gm-ui-hover-effect {
				img {
					background-color: $background-color_1;
					border-radius: 100px;
				}
			}
		}
		.gm-style-iw-t {
			&::after {
				background: #1F1F1F;
				color: $color_1;
			}
		}
	}
}
.highContrastContainer {
	.gm-style {
		.gm-style-iw-d {
			background: black;
			color: $color_1;
		}
		.gm-style-iw-c {
			background: black;
			color: $color_1;
			.gm-ui-hover-effect {
				img {
					background-color: $background-color_1;
					border-radius: 100px;
				}
			}
		}
		.gm-style-iw-t {
			&::after {
				background: black;
				color: $color_1;
			}
		}
	}
}
.gm-style {
	.gm-style-iw-d {
		.content {
			margin-right: 10px;
			.siteNotice {
				text-wrap: normal;
				max-width: 300px;
			}
		}
	}
}
