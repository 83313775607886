.tag-multiple-dropdown {
    min-height: 34px;
    max-height: 34px;
    max-width: 200px !important;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    
    & .ui-dropdown__selected-items {
        width: 85% !important;
        display: flex !important;
        flex-wrap: nowrap !important;
        overflow-x: scroll !important;
        overflow-y: hidden !important
    };
    
    & .ui-dropdown__selecteditem {
      font-size: 14px; 
      padding: 2px 4px;
      margin-right: 4px; 
      max-height: 14px !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    };
    & .ui-dropdown__searchinput {
      max-height: 18px !important
    }
  }