.contactDialog-group-pill {
  padding-left: 0.75rem;
  border-radius: 20px;
  margin: 0.5rem 0;
  position: relative;

  #teamsDefault & {
    background-color: rgb(245, 245, 245);
  }

  #teamsDark & {
    background-color: rgb(37, 36, 35);
  }
}
