.slider-container {
  width: 100%;
  position: relative;
  z-index: 0 !important;
}

.caroussel-left-button,
.caroussel-right-button {
  position: relative;
  z-index: 0 !important;
}

.slider {
  width: 80vw;
  display: flex;
  flex-flow: row nowrap;
  transition: 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  margin-left: 3.5rem;
  margin-right: 3.5rem;
}

@media screen and (max-width: 768px) {
  .slider {
    margin-left: 0;
    margin-right: 0;
  }

  .caroussel-left-button {
    transform: translateX(-35px);
  }

  .caroussel-right-button {
    transform: translateX(-10px);
  }
}
