.whiteBackground {
    background-color: #F5F5F5 !important;
}

#margin-bottom{
    margin-bottom: 1.875rem!important;
}

#no-margin {
    margin: 0!important;
}

#perm-accordion > div{
    margin-bottom: 20px!important;
}

#perm-accordion > div[role="heading"]{
    margin-bottom: 0px!important;
    width: fit-content!important;
    z-index: 899;
}

#manage-table-perm-pos{
    margin-left: -1.25rem!important;
    margin-top: -30px !important;
}

#margin-right {
    margin-right: 10px!important;
}

a {
    color: inherit; /* Garde la couleur par défaut du texte */
}

.permissions-top-nav{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 1rem;
}



@media screen and (max-width: 768px) {
    .permissions-btns-container{
        padding-top: 2.5rem;
    }
}

@media screen and (max-width: 500px) {

    .permissions-top-nav-left{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }

    .permissions-top-nav{
        flex-flow: column nowrap;
        padding: 0 1rem;
    }

    .permissions-top-nav-btns{
        width: 100%!important;
    }

.permissions-top-nav-btns button{
    width: 100%!important;
}

.permissions-top-nav-btns div{
    width: 100%!important;
}

}